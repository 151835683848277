import React from "react";

const FiltersDash = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="16"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.75 9C5.75 8.58579 6.08579 8.25 6.5 8.25L18.5 8.25C18.9142 8.25 19.25 8.58579 19.25 9C19.25 9.41421 18.9142 9.75 18.5 9.75L6.5 9.75C6.08579 9.75 5.75 9.41421 5.75 9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75 13C7.75 12.5858 8.08579 12.25 8.5 12.25L16.5 12.25C16.9142 12.25 17.25 12.5858 17.25 13C17.25 13.4142 16.9142 13.75 16.5 13.75L8.5 13.75C8.08579 13.75 7.75 13.4142 7.75 13Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 17C9.75 16.5858 10.0858 16.25 10.5 16.25L14.5 16.25C14.9142 16.25 15.25 16.5858 15.25 17C15.25 17.4142 14.9142 17.75 14.5 17.75H10.5C10.0858 17.75 9.75 17.4142 9.75 17Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 2.75C17.6086 2.75 21.75 6.89137 21.75 12C21.75 17.1086 17.6086 21.25 12.5 21.25C7.39137 21.25 3.25 17.1086 3.25 12C3.25 6.89137 7.39137 2.75 12.5 2.75ZM23.25 12C23.25 6.06294 18.4371 1.25 12.5 1.25C6.56294 1.25 1.75 6.06294 1.75 12C1.75 17.9371 6.56294 22.75 12.5 22.75C18.4371 22.75 23.25 17.9371 23.25 12Z"
      fill="currentColor"
    />
  </svg>
);

export default FiltersDash;
