import React from "react";

const Csv = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.269904" y="0.77027" width="20.4595" height="19.4595" stroke="#001450" strokeWidth="0.540541"/>
    <line x1="0.384399" y1="4.32543" x2="20.9249" y2="4.32543" stroke="black" strokeWidth="0.540541"/>
    <line x1="0.384399" y1="8.64965" x2="20.9249" y2="8.64965" stroke="black" strokeWidth="0.540541"/>
    <line x1="0.384399" y1="12.2297" x2="20.9249" y2="12.2297" stroke="black" strokeWidth="0.540541"/>
    <line x1="0.384399" y1="16.2297" x2="20.9249" y2="16.2297" stroke="black" strokeWidth="0.540541"/>
    <line x1="5.2699" y1="0.5" x2="5.2699" y2="20.5" stroke="black" strokeWidth="0.540541"/>
  </svg>
);

export default Csv;
