import React from "react";

const Delivery = () => (
  <svg
    width="28"
    height="30"
    viewBox="0 0 28 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 17.0218C10.2133 17.0218 7.19678 13.8913 7.19678 10.1087V6.91305C7.19678 3.06522 10.2133 0 14 0C17.7866 0 20.8031 3.13044 20.8031 6.91305V10.1087C20.8031 13.8913 17.7866 17.0218 14 17.0218ZM14 1.30435C10.9193 1.30435 8.4804 3.84783 8.4804 6.91305V10.1087C8.4804 13.2391 10.9835 15.7174 14 15.7174C17.0165 15.7174 19.5195 13.1739 19.5195 10.1087V6.91305C19.5195 3.78261 17.0806 1.30435 14 1.30435Z"
      fill="currentColor"
    />
    <path
      d="M24.3331 19.5654L16.6956 17.9023V16.0436C16.6956 15.848 16.6314 15.6523 16.4389 15.5219C16.2463 15.3914 16.0538 15.3914 15.8612 15.4567C15.2836 15.5871 14.706 15.7175 14.1284 15.7175H13.8716C13.294 15.7175 12.7164 15.6523 12.1388 15.4567C11.9462 15.3914 11.6895 15.3914 11.5611 15.5219C11.4328 15.6523 11.3044 15.848 11.3044 16.0436V17.9023L3.67329 19.5654C2.06877 19.9567 0.875 21.4567 0.875 23.1523V29.348C0.875 29.7393 1.13814 30.0001 1.52323 30.0001C1.90831 30.0001 2.16504 29.7393 2.16504 29.348V23.1523C2.17145 22.0436 2.83894 21.1306 3.93001 20.8697L11.4905 19.298C11.882 20.3219 12.864 21.0654 14 21.0654C15.136 21.0654 16.118 20.3219 16.5095 19.298L24.0764 20.8697C25.1675 21.1306 25.835 22.0436 25.835 23.1523V29.348C25.8414 29.7393 26.0981 30.0001 26.4832 30.0001C26.8683 30.0001 27.125 29.7393 27.125 29.348V23.1523C27.125 21.4567 25.9377 19.9567 24.3331 19.5654ZM15.412 18.3262C15.412 19.1088 14.7702 19.761 14 19.761C13.2298 19.761 12.588 19.1088 12.588 18.3262V16.8914C12.9731 16.9567 13.4224 17.0219 13.8716 17.0219H14.1284C14.5776 17.0219 14.9627 16.9567 15.412 16.8914V18.3262Z"
      fill="currentColor"
    />
    <path
      d="M21.2399 29.9286C21.2399 25.9046 18.0308 22.8003 14.0709 22.8003C10.1109 22.8003 6.90186 25.9046 6.90186 29.9286L8.44862 29.909C8.44862 27.0851 10.5538 24.6264 13.2558 24.3329V28.1938C13.2558 28.1938 13.2558 28.2199 13.2558 28.2329C13.2301 28.5329 13.0953 29.7133 12.415 29.909H15.5534C14.8731 29.7199 14.7383 28.5329 14.7127 28.2329C14.7127 28.2199 14.7127 28.2068 14.7127 28.1938V24.3199C17.4725 24.5612 19.6354 27.0394 19.6354 29.9025L21.2399 29.922V29.9286Z"
      fill="currentColor"
    />
  </svg>
);

export default Delivery;
