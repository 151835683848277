import React from "react";

const DashboardBars = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height={24} viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="m22.5,24h-4.1c-.83,0-1.5-.67-1.5-1.49V1.49c0-.88.62-1.49,1.5-1.49h4.1c.87,0,1.5.63,1.5,1.49v21.01c0,.87-.63,1.49-1.5,1.49ZM18.4,1c-.32,0-.5.18-.5.5v21.01c0,.31.26.5.5.5h4.1c.32,0,.5-.18.5-.5V1.49c0-.32-.18-.5-.5-.5h-4.1Z"
    />
    <path
      fill="currentColor"
      d="m14.05,24h-4.1c-.83,0-1.5-.67-1.5-1.49V7.47c0-.87.63-1.49,1.5-1.49h4.1c.88,0,1.5.61,1.5,1.49v15.04c0,.88-.62,1.49-1.5,1.49ZM9.95,6.97c-.32,0-.5.18-.5.5v15.04c0,.31.26.5.5.5h4.1c.32,0,.5-.18.5-.5V7.47c0-.32-.18-.5-.5-.5h-4.1Z"
    />
    <path
      fill="currentColor"
      d="m5.6,24H1.5c-.87,0-1.5-.63-1.5-1.49v-8.17c0-.88.62-1.49,1.5-1.49h4.1c.88,0,1.5.61,1.5,1.49v8.17c0,.87-.63,1.49-1.5,1.49ZM1.5,13.84c-.32,0-.5.18-.5.5v8.17c0,.32.18.5.5.5h4.1c.32,0,.5-.18.5-.5v-8.17c0-.32-.18-.5-.5-.5H1.5Z"
    />
  </svg>
);

export default DashboardBars;
