import React from "react";

const Export = () => (
  <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.92709 15.9966L9.29542 15.9966C9.41948 15.9966 9.48799 16.1188 9.41207 16.2029L7.33828 18.4518C7.32442 18.4669 7.30672 18.4792 7.2865 18.4877C7.26629 18.4961 7.24411 18.5005 7.22163 18.5005C7.19914 18.5005 7.17696 18.4961 7.15675 18.4877C7.13654 18.4792 7.11883 18.4669 7.10497 18.4518L5.03118 16.2013C4.95526 16.1188 5.02377 15.9966 5.14783 15.9966L6.51987 15.9966L6.51987 10.6275C6.51987 10.5576 6.58653 10.5005 6.668 10.5005L7.77896 10.5005C7.86043 10.5005 7.92709 10.5576 7.92709 10.6275L7.92709 15.9966Z"
      fill="#2369BD"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9.11467 0.160885L12.8373 3.84412C12.9411 3.94682 12.9999 4.08716 12.9999 4.23264V14.7877C12.9999 15.0907 12.7525 15.3354 12.4464 15.3354H9.84248V14.1031H11.7544V5.64809H8.01794C7.82525 5.64809 7.64046 5.57235 7.5042 5.43754C7.36795 5.30273 7.29141 5.11989 7.29141 4.92924V1.23231H2.06727V14.1031H4.42998V15.3354H1.37533C1.06915 15.3354 0.821777 15.0907 0.821777 14.7877V0.547693C0.821777 0.24475 1.06915 0 1.37533 0H8.72372C8.87076 0 9.01087 0.0581925 9.11467 0.160885ZM8.4677 4.48424H11.7233L8.4677 1.26312V4.48424Z"
          fill="#2369BD"/>
  </svg>

);

export default Export;
