import React from "react";

const CustomerService = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.885 33.425C10.1159 33.4343 9.35261 33.2916 8.63879 33.0053C7.92497 32.719 7.27467 32.2946 6.72517 31.7565C6.17567 31.2184 5.73777 30.5771 5.43658 29.8694C5.13539 29.1618 4.97682 28.4016 4.96997 27.6325V25.375C4.97459 23.8402 5.58635 22.3695 6.67165 21.2842C7.75696 20.1989 9.22762 19.5871 10.7625 19.5825H10.885C11.1263 19.5825 11.3578 19.6784 11.5284 19.8491C11.6991 20.0197 11.795 20.2512 11.795 20.4925V32.515C11.795 32.7564 11.6991 32.9878 11.5284 33.1585C11.3578 33.3291 11.1263 33.425 10.885 33.425ZM9.99247 21.4725C9.08737 21.6505 8.27191 22.1366 7.68484 22.8481C7.09777 23.5597 6.77536 24.4526 6.77247 25.375V27.6325C6.78339 28.5489 7.10945 29.4336 7.69585 30.1379C8.28224 30.8423 9.09322 31.3232 9.99247 31.5V21.4725Z"
      fill="#001450"/>
    <path
      d="M45.2899 33.425H45.1849C44.9436 33.425 44.7121 33.3291 44.5414 33.1584C44.3708 32.9878 44.2749 32.7563 44.2749 32.515V20.4925C44.2749 20.2511 44.3708 20.0196 44.5414 19.849C44.7121 19.6783 44.9436 19.5825 45.1849 19.5825C45.954 19.5732 46.7173 19.7158 47.4311 20.0022C48.1449 20.2885 48.7952 20.7129 49.3447 21.251C49.8942 21.7891 50.3321 22.4304 50.6333 23.138C50.9345 23.8457 51.0931 24.6059 51.0999 25.375V27.6325C51.0953 29.1703 50.4811 30.6436 49.392 31.7294C48.3029 32.8152 46.8278 33.425 45.2899 33.425ZM46.0774 21.4725V31.5C46.9808 31.3215 47.7941 30.8347 48.3783 30.1228C48.9624 29.411 49.2811 28.5183 49.2799 27.5975V25.375C49.2811 24.4541 48.9624 23.5614 48.3783 22.8496C47.7941 22.1377 46.9808 21.6509 46.0774 21.4725Z"
      fill="#001450"/>
    <path
      d="M29.2776 35.5249H26.7576C25.7911 35.5249 25.0076 36.3084 25.0076 37.2749V37.7824C25.0076 38.7489 25.7911 39.5324 26.7576 39.5324H29.2776C30.2441 39.5324 31.0276 38.7489 31.0276 37.7824V37.2749C31.0276 36.3084 30.2441 35.5249 29.2776 35.5249Z"
      fill="#001450"/>
    <path
      d="M41.1599 38.4998H28.6299C28.4945 38.5385 28.3519 38.5452 28.2135 38.5194C28.075 38.4937 27.9444 38.4361 27.832 38.3513C27.7195 38.2665 27.6283 38.1567 27.5655 38.0306C27.5027 37.9046 27.47 37.7656 27.47 37.6248C27.47 37.484 27.5027 37.345 27.5655 37.219C27.6283 37.0929 27.7195 36.9831 27.832 36.8983C27.9444 36.8135 28.075 36.7559 28.2135 36.7302C28.3519 36.7044 28.4945 36.7111 28.6299 36.7498H41.1599C44.2224 36.7498 44.2749 32.8123 44.2749 32.6373C44.2749 32.5186 44.2986 32.4011 44.3445 32.2917C44.3904 32.1822 44.4577 32.083 44.5425 31.9999C44.6272 31.9168 44.7277 31.8515 44.838 31.8076C44.9483 31.7638 45.0663 31.7425 45.1849 31.7448C45.4216 31.7448 45.6486 31.8388 45.816 32.0062C45.9834 32.1736 46.0774 32.4006 46.0774 32.6373C46.0774 34.5623 45.0624 38.4998 41.1599 38.4998Z"
      fill="#001450"/>
    <path
      d="M28.0001 52.9376C26.5643 52.933 25.1889 52.3594 24.1753 51.3425C23.1617 50.3256 22.5926 48.9484 22.5926 47.5126V42.6126C22.5912 42.474 22.6221 42.3371 22.6829 42.2125C22.7436 42.088 22.8325 41.9793 22.9426 41.8951C23.0591 41.816 23.1919 41.7639 23.3312 41.7427C23.4705 41.7215 23.6127 41.7317 23.7476 41.7726C25.0243 42.2184 26.3677 42.4433 27.7201 42.4376H28.2976C29.6498 42.4416 30.9929 42.2167 32.2701 41.7726C32.402 41.7319 32.5415 41.7218 32.6779 41.743C32.8144 41.7643 32.9442 41.8163 33.0576 41.8951C33.1698 41.9784 33.2614 42.0865 33.3251 42.2109C33.3888 42.3354 33.423 42.4728 33.4251 42.6126V47.5126C33.4251 48.9514 32.8535 50.3313 31.8361 51.3486C30.8187 52.366 29.4389 52.9376 28.0001 52.9376ZM24.3601 43.7501V47.4776C24.3253 47.9803 24.3943 48.4849 24.5628 48.9598C24.7314 49.4348 24.9957 49.87 25.3396 50.2384C25.6834 50.6069 26.0994 50.9007 26.5616 51.1015C27.0238 51.3024 27.5224 51.406 28.0263 51.406C28.5303 51.406 29.0289 51.3024 29.4911 51.1015C29.9533 50.9007 30.3692 50.6069 30.7131 50.2384C31.0569 49.87 31.3213 49.4348 31.4898 48.9598C31.6583 48.4849 31.7273 47.9803 31.6926 47.4776V43.7501C30.5861 44.0142 29.4527 44.1493 28.3151 44.1526H27.7376C26.5999 44.1504 25.4664 44.0153 24.3601 43.7501Z"
      fill="#001450"/>
    <path
      d="M28.4542 44.1874H27.5967C23.8836 44.1874 20.3227 42.7124 17.6972 40.0869C15.0717 37.4614 13.5967 33.9004 13.5967 30.1874V22.3999C13.5967 18.6869 15.0717 15.1259 17.6972 12.5004C20.3227 9.8749 23.8836 8.3999 27.5967 8.3999H28.4542C32.1672 8.3999 35.7282 9.8749 38.3537 12.5004C40.9792 15.1259 42.4542 18.6869 42.4542 22.3999V30.1699C42.4565 32.0099 42.0961 33.8323 41.3935 35.5328C40.691 37.2334 39.6601 38.7788 38.3599 40.0807C37.0596 41.3826 35.5155 42.4154 33.8158 43.12C32.1161 43.8247 30.2942 44.1874 28.4542 44.1874ZM27.5967 10.1324C24.3478 10.1324 21.2319 11.423 18.9346 13.7203C16.6373 16.0177 15.3467 19.1335 15.3467 22.3824V30.1524C15.3467 33.4013 16.6373 36.5171 18.9346 38.8145C21.2319 41.1118 24.3478 42.4024 27.5967 42.4024H28.4542C31.7031 42.4024 34.8189 41.1118 37.1162 38.8145C39.4136 36.5171 40.7042 33.4013 40.7042 30.1524V22.3999C40.7042 19.151 39.4136 16.0352 37.1162 13.7378C34.8189 11.4405 31.7031 10.1499 28.4542 10.1499L27.5967 10.1324Z"
      fill="#001450"/>
    <path
      d="M45.167 22.9776C44.935 22.9776 44.7124 22.8854 44.5483 22.7213C44.3842 22.5572 44.292 22.3347 44.292 22.1026C44.292 17.9579 42.6456 13.983 39.7148 11.0523C36.7841 8.12156 32.8092 6.4751 28.6645 6.4751H27.3695C23.2279 6.47974 19.2575 8.12825 16.3305 11.0585C13.4036 13.9887 11.7595 17.961 11.7595 22.1026C11.755 22.3333 11.6614 22.5532 11.4983 22.7164C11.3351 22.8795 11.1152 22.9731 10.8845 22.9776C10.6525 22.9776 10.4299 22.8854 10.2658 22.7213C10.1017 22.5572 10.0095 22.3347 10.0095 22.1026C10.0095 17.4968 11.838 13.0794 15.0931 9.82103C18.3482 6.56262 22.7638 4.72974 27.3695 4.7251H28.6645C33.2719 4.72973 37.6892 6.56206 40.9472 9.81997C44.2051 13.0779 46.0374 17.4952 46.042 22.1026C46.042 22.3347 45.9498 22.5572 45.7857 22.7213C45.6216 22.8854 45.3991 22.9776 45.167 22.9776Z"
      fill="#001450"/>
  </svg>

);

export default CustomerService;
